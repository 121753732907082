/*export const COULEUR_VICTOIRE = 'rgba(157, 85, 225, 0.85)';
export const COULEUR_DEFAITE = 'rgba(255, 250, 109, 0.62)';
export const COULEUR_BORDER_VICTOIRE = 'rgb(186, 112, 255)';
export const COULEUR_BORDER_DEFAITE = 'rgb(255, 250, 109)';*/


export const COULEUR_VICTOIRE = 'rgba(76, 231, 38, 0.564)';
export const COULEUR_DEFAITE = 'rgba(255, 1, 1, 0.566)';
export const COULEUR_BORDER_VICTOIRE = 'rgb(77, 231, 38)';
export const COULEUR_BORDER_DEFAITE = 'rgb(255, 1, 1)';

